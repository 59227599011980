import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { Link } from "gatsby"

import Slider from "react-slick"
import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import agencement from "../assets/img/realisations/agencement.jpg"
import restauration from "../assets/img/realisations/restauration.jpg"
import residentiel from "../assets/img/realisations/residentiel.jpg"
import sante from "../assets/img/realisations/sante.jpg"
import vasque from "../assets/img/realisations/vasque.jpg"
import hotellery from "../assets/img/realisations/hotellery.jpg"
import almogar from "../assets/img/realisations/last-projects/almogar.jpg"
import oasis from "../assets/img/realisations/last-projects/oasis.jpg"
import caprice from "../assets/img/realisations/last-projects/caprice.jpg"
import caramel from "../assets/img/realisations/last-projects/caramel.jpg"
import taiba from "../assets/img/realisations/last-projects/taiba.jpg"
import csvtpm from "../assets/img/realisations/last-projects/csvtpm.jpg"
import pcuisine from "../assets/img/realisations/last-projects/pcuisine.png"

const RealisationsPage = ({ data }) => {
    
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };
  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    arrows: false,
  };
  const settingsRef = {
    slidesToShow: 7,
    slidesToScroll: 3,
    arrows: true, 
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
  };
  //const images = data.gallery.edges.map(({ node }) => node.childImageSharp)
  return(
      <Layout>
        <SEO title="Nos réalisations" />
        <div class="w-full relative">
            <div className="slider-wrapper">
              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto w-1/4">
                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.sld.edges.map(({ node, index }) =>
                      <div className="slick-slide slick-slide-thumb" key={index}>
                          <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
        <div class="grid grid-cols-1 mt-8 gap-1 md:mx-40 md:my-5 mx-3">
          <h1 class="text-xl text-center font-bold md:mt-10">DÉCOUVREZ LES RÉALISATIONS DU CORSAF DESIGN : CUISINES, SALLES DE BAINS …</h1>
          <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
          <h1 class="text-2xl font-bold mb-5 md:mt-10 text-center text-red-700">DISPONIBLE EN PLUS DE 80 COLORIS</h1>
          <h1 class="text-xl font-bold mb-8 md:mt-4 text-center text-gray-500">
            ÉCLAIREZ, COMBINEZ,THERMOFORMEZ, COURBEZ, ILLUMINEZ, SUBLIMEZ, GRAVEZ et COLORISEZ.
          </h1>
          <p class="text-center font-bold text-lg">Le Solid Surface peut prendre toutes les formes que vous imaginez.</p>
          <p class="text-center">
          Nous réalisons la création sur mesure et la pose de vos plans de travail, plans de cuisines, salles de bain, banques d’accueil, paillasses médicales et de laboratoires, mobiliers urbains, et Toutes autres réalisations pour le particulier, le professionnel et les collectivités territoriales.
          </p>
          <div class="divider mt-4"></div>
        </div>
        <div class="grid grid-cols-1 mt-8 gap-1 md:mx-40 md:my-5 mx-3">
            <h1 class="text-2xl text-center font-bold md:mt-10">Nos Réalisations</h1>
            <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5 mb-5"></div>
            <div class="md:flex md:gap-3 realisations">
               <div class="md:w-1/3 relative">
                 <Link to={`/realisations/agencement`}><img src={agencement} alt="agencement" /> <span class="absolute bottom-7 right-0 px-3 py-2 bg-gray-700 font-bold text-white">Agencement</span></Link>
                 </div>
               <div class="md:w-1/3 relative">
                 <Link to={`/realisations/restauration`}><img src={restauration} alt="restauration" /><span class="absolute bottom-7 right-0 px-3 py-2 bg-gray-700 font-bold text-white">Restauration</span></Link>
                 </div>
               <div class="md:w-1/3 relative">
                 <Link to={`/realisations/residentiel`}><img src={residentiel} alt="residentiel" /><span class="absolute bottom-7 right-0 px-3 py-2 bg-gray-700 font-bold text-white">Réalisation Résidentielle</span></Link>
                 </div>
            </div>
            <div class="md:flex md:gap-3 realisations">
               <div class="md:w-1/3 relative">
                 <Link to={`/realisations/sante-et-collectivite`}><img src={sante} alt="sante" /><span class="absolute bottom-7 right-0 px-3 py-2 bg-gray-700 font-bold text-white">Santé & Collectivité</span></Link>
                 </div>
               <div class="md:w-1/3 relative">
                 <Link to={`/realisations/vasque`}><img src={vasque} alt="vasque" /><span class="absolute bottom-7 right-0 px-3 py-2 bg-gray-700 font-bold text-white">Vasques</span>
                 </Link></div>
               <div class="md:w-1/3 relative">
               <Link to={`/realisations/hotellerie`}><img src={hotellery} alt="hôtellerie" /><span class="absolute bottom-7 right-0 px-3 py-2 bg-gray-700 font-bold text-white">Hôtellerie</span>
               </Link></div>
            </div>
        </div>
        <div class="grid grid-cols-1 mt-8 gap-1 md:mx-40 md:my-5 mx-3">
            <h1 class="text-2xl text-center font-bold md:mt-10">Nos Derniers Projets</h1>
            <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5 mb-7"></div>          
        </div>
        <div class="grid grid-cols-1 mt-8 gap-1 md:mx-40 md:my-5 mx-3">
            <div class="md:flex md:gap-3 realisations project">
              <div class="md:w-1/3 relative">
                <Link to={'/projects/pharmacy-belfaa'}><img src={almogar} alt="Hôtel Almogar" /> <span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Comptoir Café à Hôtel Club Al Moggar</span></Link></div>
              <div class="md:w-1/3 relative">
                <Link to={`/projects/comptoir-a-costa-sud`}><img src={oasis} alt="Hôtel et SPA Oasis" /><span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Comptoir à oasis hôtel & spa</span></Link></div>
              <div class="md:w-1/3 relative">
                <Link to={`/projects/plan-de-cuisine/`}><img src={pcuisine} alt="plan de cuisine" /><span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Comptoir à Boulangerie & Café Caprice Résidentielle</span></Link></div>
            </div>
            <div class="md:flex md:gap-3 realisations project">
              <div class="md:w-1/3 relative">
                <Link to={'/projects/comptoir-cafe-a-hotel-club-al-moggar'}><img src={almogar} alt="Hôtel Almogar" /> <span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Comptoir Café à Hôtel Club Al Moggar</span></Link></div>
              <div class="md:w-1/3 relative">
                <Link to={`/projects/comptoir-a-oasis-hotel-spa`}><img src={oasis} alt="Hôtel et SPA Oasis" /><span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Comptoir à oasis hôtel & spa</span></Link></div>
              <div class="md:w-1/3 relative">
                <Link to={`/projects/comptoir-a-cafe-caprice/`}><img src={caprice} alt="Café Caprice" /><span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Comptoir à Boulangerie & Café Caprice Résidentielle</span></Link></div>
            </div>
            <div class="md:flex md:gap-3 realisations project">
              <div class="md:w-1/3 relative">
                <Link to={`/projects/plan-de-travail-etageres-evier-csvtpm-agadir.js/`}><img src={csvtpm} alt="Plan de travail, Évier & Étagères à CSVTPM Agadir" /><span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Plan de travail, Évier & Étagères à CSVTPM Agadir</span></Link></div>
              <div class="md:w-1/3 relative">
                <Link to={`/projects/comptoir-de-restauration-a-cafe-taiba-agadir/`}><img src={taiba} alt="Comptoir de restauration à Café TAIBA AGADIR" /><span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Comptoir de restauration à Café TAIBA AGADIR</span></Link></div>
              <div class="md:w-1/3 relative">
                <Link to={`/projects/comptoir-de-restauration-a-caramel-agadir/`}><img src={caramel} class="rounded" alt="Comptoir de restauration à Caramel Agadir" /><span class="absolute bottom-12 left-0 px-3 py-2 bg-gray-700 font-bold text-white">Comptoir de restauration à Caramel Agadir</span></Link></div>
            </div>
        </div>
        <div class="grid grid-cols-1 mt-8 gap-1 md:mx-40 md:my-5 mx-3">
            
            <Link to={`/realisations-2`} class="bg-blue-900 w-40 mx-auto hover:bg-blue-800 text-white font-bold py-2 px-4 rounded">En savoir plus</Link>
        </div>
        <div class="grid grid-cols-1 mt-8 gap-1 md:mx-40 md:my-5 mx-3 pb-5">
            <h1 class="text-2xl text-center font-bold md:mt-10">Nos Références</h1>
            <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5 mb-7"></div>
             <div className="thumbnail-slider-wrap references ref-wrap mx-auto w-full mb-7">
              <Slider  {...settingsRef} >
                { data.sld2.edges.map(({ node, index }) =>
                   <div className="slick-slide ref-thumb references mx-3" key={index}>
                      <Img className="slick-slide-image rounded-full mx-3" fluid={node.childImageSharp.thumb} />
                   </div>
                )}
              </Slider>
            </div>
        </div>
      </Layout>
)
}
export const query = graphql`
query RealisationsGallery {
    sld:allFile (filter:{relativeDirectory: {eq: "img/realisations/s"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sld2:allFile (filter:{relativeDirectory: {eq: "img/realisations/ref"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
}
`
export default RealisationsPage